import Vue from "vue/dist/vue.esm";
import ComponentsLatestBoxes from './components/the-jushou-list.vue';
import ComponentsNanakakeBoxes from './components/the-nanakake-list.vue';
import '../app';

// ui状態記憶のクラス呼び出し
var usageHistory = new chari.UsageHistory();

const vm = new Vue({
  el: '#vue-portal',
  data: {
    nanakakeTabs: 1, // 1: keirin, 2: autorace
    jushouTabs: 4, // dokanto: 1, chariloto: 3, chariloto_select: 4, chariloto_5: 5, chariloto_3: 6
    items: [],
    latestBoxes: []
  },
  created: function() {
    this.nanakakeTabs = usageHistory.topNanakakeTab ? usageHistory.topNanakakeTab : 1;
    this.jushouTabs = usageHistory.topJushouTab ? usageHistory.topJushouTab : 4;
  },
  mounted: function () {
    this.fetchLatestBoxes(this.targetType);
  },
  components: {
    'jushou-list': ComponentsLatestBoxes,
    'nanakake-boxes': ComponentsNanakakeBoxes
  },
  methods: {
    switchNanakake: function(type) {
      this.nanakakeTabs = type;
      usageHistory.topNanakakeTab = type;
    },
    switchJushou: function(type) {
      this.jushouTabs = type;
      usageHistory.topJushouTab = type;
    },
    getJushouLabel: function (type) {
      return Object.keys(chari.const.KEIRIN_BOX_TYPE).find(function(e) {
        return chari.const.KEIRIN_BOX_TYPE[e] === type;
      })
    },
    fetchLatestBoxes: function(box_type) {
      // this.isLoaded = false;
      this.latestBoxes = [];

      $.ajax({
        url: "/api/keirin_jushous/list_with_box_type",
        data: {
          box_type: box_type
        }
      }).done(function(res) {
        this.latestBoxes = res;
        // this.isLoaded = true;

      }.bind(this));
    }
  },
  watch: {
    targetType: function() {
      this.fetchLatestBoxes(this.targetType);
    }
  },
  computed: {
    // gameType: function() {
    //   return this.nanakakeTabs;
    // },
    targetType: function() {
      return chari.util.getKey(chari.const.KEIRIN_BOX_TYPE, this.jushouTabs);
    }
  }
});
